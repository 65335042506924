@import '../../../styles/variables.scss';

.ticketContainer {
  padding: 350px 60px 0;
  background-color: #440f11;
  height: 1725px;
  box-sizing: border-box;
  transform: translateY(-75px);


  .ticketHeader {
    box-sizing: border-box;
    width: 960px;
    height: 80px;
    background-color: #ce0e2d;
    border-radius: 20px 20px 0px 0px;
    font-size: 36px;
    color: #333333;
    font-weight: bold;
    color: white;
    padding: 20px 60px 0;

    .departDate {
      float: right;
    }
  }

  .ticketBody {
    display: flex;
    width: 960px;
    height: 310px;
    background-color: white;
    position: relative;
    padding: 20px 0;
  }

  .originContainer {
    margin-left: 60px;
    margin-top: 40px;
    height: 200px;
    width: 208px;
    font-size: 16px;

    .durationText {
      margin-top: 20px;
      font-size: 16px;
      color: #333333;
      text-decoration: underline;
    }

    .ticketType {
      background: url('../../../assets/journey-select-arrow.svg');
      background-size: 15px;
      background-repeat: no-repeat;
      background-position: center left;
      padding-left: 30px;
      font-weight: bold;
      font-size: 28px;
      position: absolute;
      margin-top: 30px;
      line-height: 44px;
      max-width: 500px;
      color: #333333;
    }

    .ticketRestrictions {
      display: inline-block;
      position: absolute;
      background: url('../../../assets/information-icon.svg') no-repeat center center;
      border-radius: 100%;
      background-color: #CE0E2D;
      width: 36px;
      height: 36px;
      margin-left: 15px;
    }
  }

  .destinationContainer {
    padding-top: 40px;
    font-size: 16px;
    width: 308px;
    height: 200px;
  }

  .validityContainer {
    padding-top: 40px;
    padding-left: 6px;

    .passengerContainer {
      padding-top: 22px;
    }

    .price {
      padding-top: 16px;
      font-size: 48px;
      font-weight: bold;
    }
  }

  .propText {
    font-size: 28px;
    font-weight: bold;
    padding-top: 10px;
  }

  .ticketFold {
    background-color: #dadada;
    width: 960px;
    height: 100px;
    font-size: 36px;
    font-weight: bold;
    border-style: dotted none dotted none;
    position: relative;

    .foldText {
      vertical-align: middle;
      margin-left: 430px;
      padding-top: 20px;
      font-size: 36px;
      display: inline-block;
    }

    .foldTotal {
      vertical-align: middle;
      margin-left: 100px;
      font-size: 48px;
      padding-top: 25px;
      display: inline-block;
    }
  }

  .ticketFooter {
    width: 960px;
    height: 280px;
    background-color: white;
    border-radius: 0px 0px 20px 20px;
    font-size: 21px;
    color: #382f2d;
    position: relative;

    .logosContainer {
      padding-left: 60px;
      padding-top: 105px;

      .applePay {
        display: inline-block;
        background: url('../../../assets/apple-pay.svg');
        width: 98px;
        height: 40px;
        transform: translateY(-10px);
      }

      .googlePay {
        display: inline-block;
        background: url('../../../assets/google-pay.png') no-repeat;
        background-size: contain;
        width: 125px;
        height: 55px;
        margin-left: 40px;
      }
    }

    .QRCode {
      position: absolute;
      right: 100px;
      margin: 60px;
      width: 160px;
      height: 160px;
    }

    .footerText {
      color: #382F2D;
      font-size: 21px;
      width: 392px;
      height: 50px;
      padding-left: 60px;
      padding-top: 54px;
    }
  }
}

.finishButton {
  background-color: #CE0E2D;
  color: white;
  border-radius: 10px;
  display: inline-block;
  padding: 20px;
  vertical-align: middle;
  margin-left: 460px;
  width: 300px;
  height: 100px;
  font-size: 36px;
  font-weight: bold;
}

.arrow {
  margin: 70px 30px 0px 0px;

  path:nth-child(2) {
    fill: black;
  }
}

.loader {
  position: absolute;
  top: calc(45% - 60px);
  left: calc(50% - 60px);
}

.ticketByte {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background-color: #440F11;
  position: absolute;

  &.upperLeft {
    left: -20px;
    top: -20px;
  }

  &.upperRight {
    right: -20px;
    top: -20px;
  }

  &.bottomLeft {
    left: -20px;
    bottom: -20px;
    z-index: 2;
  }

  &.bottomRight {
    right: -20px;
    bottom: -20px;
    z-index: 2;
  }
}

.restrictionsModal {
  padding: 370px 200px;
  text-align: center;

  .ticketRestrictionTitle {
    font-size: 48px;
    color: white;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 80px;
  }

  .restriction {
    font-size: 37px;
    color: white;
    word-break: break-word;
    font-weight: bold;
  }
}

.button {
  width: 420px;
  height: 80px;
  background-color: #ce0e2d;
  border-radius: 10px;
  color: #ffffff;
  font-size: 32px;
  font-weight: bold;
  border: none;

  &.search {
    display: block;
    margin: 0 auto;
    width: 250px;
    margin-top: 120px;
  }
}

.restrictions {
  @include auto-vertical-shadows(800px);
  overflow: scroll;
  margin-bottom: 50px;
}

.errorContainer {
  color: white;

  h1 {
    font-size: 50px;
  }

  h2 {
    font-size: 40px;
  }
}