@import '../../../styles/variables.scss';

.key {
    font-size: 36px;
    text-transform: capitalize;
    font-weight: bold;
}

.normalKey {
    @extend .key;
    max-width: 96px !important;
    height: 96px !important;
    background: $keyboard-key-background !important;
    color: $keyboard-key-color !important;
}

.space {
    @extend .key;
    max-width: 396px !important;
    height: 96px !important;
    background: $keyboard-space-background !important;
    color: $keyboard-space-color !important;
}

.backspace {
    @extend .normalKey;
    background: $keyboard-backspace-background !important;
    color: $keyboard-backspace-color !important;
}

.inputWrapper {
    position: relative;
    overflow: hidden;

    .input {
        width: 100%;
        height: 100%;
        height: 135px;
        background: #DADADA;
        padding: 0 155px 0 70px;
        font-size: 96px;
        border: none;
        outline: none;
        box-sizing: border-box;
        text-transform: capitalize;
    }

    .clearButton {
        position: absolute;
        top: 30px;
        right: 70px;
        font-size: 40px;
        color: white;

        &.enabled {
            background: #382F2D;
        }
    }
}

.autocompleteWrapper {
    height: 250px;
    padding: 30px 70px;
    margin: 0;
    list-style-type: none;
    overflow: auto;

    &:after {
        content: "";
        position: absolute;
        z-index: 1;
        bottom: -1px;
        left: 0;
        pointer-events: none;
        background-image: linear-gradient(to bottom,
                rgba(255, 255, 255, 0),
                rgba(251, 251, 251, 1) 90%);
        width: 100%;
        height: 4em;
    }

    .autocompleteItem {
        display: flex;
        width: 100%;
        margin: 0 0 16px;
        font-size: 40px;
        white-space: break-spaces;

        .highlight {
            font-weight: bold;
        }
    }
}