.modal {
    position: absolute;
    z-index: 10;
    opacity: 0;
    transition: all .3s ease;
    visibility: hidden;
    background-color: #440F11;
    width: 100vw;
    height: 100vh;
    top: 0;

    &.visible {
        opacity: 1;
        visibility: visible;
    }

}