.journeyBar {
  display: flex;
  height: 110px;
  background: #dadada80;
  color: #382f2d;
  font-size: 42px;
  font-weight: bold;
}

.journeyBar:nth-of-type(even) {
  // background: #ffffff;
  background: #dadada4d;
  //   font-size: 42px;
}

.priceBox {
  width: 200px;
  height: 80px;
  background: #382f2d;
  color: #ffffff;
  text-align: left;
  border-radius: 10px;
  font-size: 42px;
  margin-top: 15px;
  margin-right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;

  svg {
    max-width: 60px;
  }

  &.priceBoxLoading {
    justify-content: center;
    padding-left: 0;
  }
}

.stationName {
  padding-top: 28px;
  padding-bottom: 32px;
  font-size: 40px;
  font-weight: bold;
  color: #382f2d;
  padding-left: 70px;
  width: 424px;
  height: 50px;
}

.priceContainer {
  display: flex;
  margin-left: 95px;
}

.divider {
  border: 3px solid #dadada;
  width: 1080px;
  // border: 3px solid #ffffff;
}

.price {
  display: flex;
  text-align: center;
  justify-content: center;

  small {
    font-size: 16px;
    align-self: flex-end;
    line-height: 30px;
    margin-right: 5px;
  }
}

.OtherTicketOptions {
  width: 420px;
  height: 80px;
  background-color: #ce0e2d;
  border-radius: 10px;
  color: #ffffff;
  font-size: 32px;
  font-weight: bold;
  border: none;
}

.position {
  padding-left: 585px;
  padding-top: 15px;
}

.penny {
  font-size: 21px;
  margin-top: 15px;
}

.options {
  display: flex;
  position: absolute;
  left: 420px;
}

.headings {
  font-size: 36px;
  font-weight: bold;
  text-align: left;
  text-transform: uppercase;
  color: white;
  margin-left: 70px;
  margin-top: 130px;
  display: flex;
}

.singleText {
  padding-left: 235px;
}

.returnText {
  padding-left: 60px;
}

.iconContainer {
  padding-left: 665px;
  padding-top: 47px;
  align-items: center;
  display: flex;
}

.returnIcon {
  padding-left: 140px;
}

.secondaryHeader {
  width: 1080px;
  height: 200px;
  background-color: #CE0E2D;
}

.spinner {
  text-align: center;
}

.eticketWarning {
  padding: 60px;
  font-weight: 300;
}

.moreInfo {
  color: #CE0E2D;
  text-decoration: underline;
}

.moreInfoModal {
  padding: 370px 130px;
  text-align: center;

  .moreInfoTitle {
    font-size: 48px;
    color: white;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 80px;
  }

  .moreInfoText {
    font-size: 37px;
    color: white;
    font-weight: bold;
    text-align: left;
    word-break: break-word;
  }

  .button {
    width: 420px;
    height: 80px;
    background-color: #ce0e2d;
    border-radius: 10px;
    color: #ffffff;
    font-size: 32px;
    font-weight: bold;
    border: none;
  }
}