.picker {
  width: 1080px;
  height: 1800px;
  background-color: #440f11;
}

.title {
  color: white;
  font-size: 48px;
  text-transform: uppercase;
  font-weight: bold;
  padding-top: 330px;
  padding-left: 389px;
}

.passengerButton {
  width: 370px;
  height: 100px;
  color: white;
  font-size: 36px;
  font-weight: bold;
  background-color: #440f1140;
  // background-color: #440f1180;
  border: none;
  border-radius: 10px 0px 0px 10px;
}

.passengerButton:disabled {
  background-color: #ce0e2d;
}

.passengerButton:nth-last-of-type(odd) {
  border-radius: 0px 10px 10px 0px;
}

.buttonContainer {
  padding-top: 56px;
  padding-left: 170px;
}

.railcardContainer {
  color: white;
  font-size: 36px;
  font-weight: bold;
  padding-top: 240px;
  text-align: center;
}

.iconContainer {
  display: flex;
  flex-direction: row;
}
.adultContainer {
  padding-top: 207px;
  padding-left: 293px;
}

.childContainer {
  padding-top: 290px;
  padding-left: 269.4px;
}

.railcardIcon {
  padding-top: 41px;
  padding-left: 400px;
}
