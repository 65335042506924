.SecondaryHeader {
  height: 200px;
  background: #ce0e2d;
  color: white;
  font-size: 36px;
  display: flex;
}

header {
  font-size: 36px;
  font-weight: bold;
  text-align: left;
  text-transform: uppercase;

  padding-top: 123px;
  padding-left: 70px;
  margin-bottom: 33px;
}

.divider {
  width: 1080px;
  height: 20px;
  background: #dadada80;
}
