.originDestinationHeader {
	background-color: #CE0E2D;
	padding: 100px 70px;
	color: #FFF;
	height: 335px;
	font-size: 36px;
	font-weight: bold;
}

.oddEvenDestinationList {
	list-style: none;
	padding: 0;
	margin: 0;

	li {
		box-sizing: border-box;
		margin: 0;
		background-color: #FFF;
		height: 140px;
		width: 100%;
		border-top: 1px solid #9A9A9A;
		padding: 35px 80px 35px 35px;
		text-overflow: ellipsis;
		white-space: pre;
		overflow: hidden;
		color: #382F2D;
		font-size: 46px;
		font-weight: bold;
		position: relative;

		&:nth-child(odd) {
			background-color: #ECECEC;
		}

		&:after {
			content: ' ';
			position: absolute;
			width: 23px;
			height: 35px;
			background: url('../../../assets/journey-select-arrow.svg');
			top: 50px;
			right: 50px;
		}

		&:last-child {
			border-bottom: 1px solid #9A9A9A;
		}

		label {
			color: #382F2D;
			font-size: 32px;
			font-weight: bold;
			width: 280px;
			display: inline-block;
		}
	}
}

.tabsRibbon {
	margin-top: -40px;
	background: var(--light-grey) 0% 0% no-repeat padding-box;
	background: #ECECEC 0% 0% no-repeat padding-box;
	width: 100%;
	height: 120px;

	&:after {
		content: ' ';
		width: 100%;
		height: 120px;
		position: absolute;
		background-color: #ECECEC;
		-webkit-box-shadow: 0px -17px 24px 0px rgba(0, 0, 0, 0.65);
		-moz-box-shadow: 0px -17px 24px 0px rgba(0, 0, 0, 0.65);
		box-shadow: 0px -17px 24px 0px rgba(0, 0, 0, 0.65);
	}
}

.tab {
	width: 500px;
	height: 135px;
	margin-left: 28px;
	border-radius: 15px;
	text-align: center;
	background-color: #693F41;
	display: inline-block;
	color: #FFF;
	font-size: 36px;
	font-weight: bold;
	line-height: 120px;
	position: relative;

	&.selected {
		background-color: #ECECEC;
		color: #000;
		position: relative;

		&:after {
			content: ' ';
			position: absolute;
			width: 100%;
			height: 50px;
			background-color: #ECECEC;
			left: 0;
			bottom: 0;
			z-index: 9;
		}
	}

}

.tabList {
	background-color: #ce0e2d;
	height: 135px;
	margin-top: -20px;
	padding: 0px;
}

.tabsContainer {
	margin: 0;
	width: 100%;
	position: relative;
	font-size: 36px;
	font-weight: bold;
	height: 1050px;
}

.findTicketsButton {
	background-color: #CE0E2D;
	color: white;
	border-radius: 10px;
	display: inline-block;
	padding: 20px;
	vertical-align: middle;
	margin-left: 460px;
	width: 300px;
	height: 100px;
	font-size: 36px;
	font-weight: bold;
}

.arrow {
	margin: 0 15px;
	transform: translateY(-10px);
}

.stationName {
	max-width: 455px;
	display: inline-block;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}