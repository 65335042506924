@import '../../../styles/variables.scss';

.Header {
    width: 1080px;
    height: 400px;
    background: #440f11;

  
    /*text stuff */
    color: #ffffff;
    letter-spacing: 0px;
    color: #ffffff;
    // text-transform: uppercase;
  }
  
  .headerText {
    width: 650px;
    text-align: left;
    padding-top: 130px;
    padding-left: 60px;
    padding-bottom: 59px;
    font-size: 96px;
    margin: 0;
  }
