.navigationBar {
    background-color: #DADADA;
	width: 100%;
	height: 172px;
	border-bottom: 2px solid #AAA;
	position: absolute;
	box-sizing: border-box;
	box-shadow: 0px -17px 24px 0px rgba(50, 50, 50, 0.47);
	vertical-align: middle;
    padding: 40px 40px;
    bottom: 100px;
}