/* Here we'll create variables for all of our theming needs
   What to include here?
     - colours
     - font names
     - font sizes
     - gradients
     - repeating styles (border-radius for e.g.)
*/

// Clock
$clock-font-color: #fff;
$clock-font-size: 60px;
$clock-font-weight: bold;

// Buttons

// RoundButton
$round-button-base-background-color: #C6C4C4;




// Keyboard
$keyboard-key-background: #382F2D;
$keyboard-key-color: #fff;
$keyboard-space-background: #DADADA;
$keyboard-space-color: #382F2D;
$keyboard-backspace-background: #DADADA;
$keyboard-backspace-color: #382F2D;
$keyboard-backspace-color: #382F2D;

// Automatic shadows top/bottom of scrollable elements.
// If it's possible to scroll to the bottom of the element => shadow bottom.
// If it's possible to scroll to the top of the element => shadow top.
// If it's possible to scroll both to the bottom and to the top of the element => shadow bottom + top.
// See https://stackoverflow.com/a/44794221.
// --------------------------------------------------
@mixin auto-vertical-shadows($max-height) {
  overflow: auto;
  max-height: $max-height;
  margin: 50px auto;
  background: /* Shadow covers */
  linear-gradient(#440F11 30%, rgba(255, 255, 255, 0)), linear-gradient(rgba(255, 255, 255, 0), #440F11 70%) 0 100%, /* Shadows */
  radial-gradient(farthest-side at 50% 0, rgba(255, 255, 255, .5), rgba(255, 255, 255, 0)), radial-gradient(farthest-side at 50% 100%, rgba(255, 255, 255, .5), rgba(255, 255, 255, 0)) 0 100%;
  background-repeat: no-repeat;
  background-color: #440F11;
  background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
  /* Opera doesn't support this in the shorthand */
  background-attachment: local, local, scroll, scroll;
}