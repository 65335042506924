// Here the main styles will live, so stuff that affect body and html and what not
@import 'normalize.css';

body {
  width: 1080px;
  height: 1920px;
  margin: 0;
  font-family: 'Gotham', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.picker-item{
	color: #FFF;
	font-size: 48px;
	font-weight: bold;
	text-align: right;
}
.picker-column:nth-child(1){
	min-width: 400px;
	.picker-item{
		text-align: right;
		padding-left: 100px;
	}
}
.picker-column:nth-child(2){
	min-width: 100px;
	padding-left: 100px;
	.picker-item{
		text-align: left;
	}
}
.picker-column:nth-child(3){
	min-width: 100px;
	.picker-item{
		text-align: left;
	}
}
.picker-item-selected{
	color: #FFF!important;
	position: relative;
}
.picker-highlight{
	background-color: #DADADA;
	opacity: .25!important;
	padding: 20px!important;
	height: 70px!important;
	margin-top: -35px!important;
	border-radius: 10px;
}