.footer {
	position: absolute;
	height: 100px;
	z-index: 999;
	background-color: #382F2D;
	bottom: 0px;
	width: 100%;
	color: #FFF;
	font-size: 28px;
	padding: 0px 50px;
	vertical-align: middle;
	box-sizing: border-box;
}

.footerDescription {
	display: inline-block;
	vertical-align: middle;
	margin-top: 15px;
}

.footerIcon {
	display: inline-block;
	vertical-align: middle;
	margin-left: 40px;
	margin-top: 29px;
}

.footerIcon img {
	max-width: 200px;
	height: 60px;
}

.LNERIcon {
	display: inline-block;
	text-align: right;
	vertical-align: middle;
	margin-left: 40px;
	margin-top: 29px;
	position: absolute;
	right: 50px;
}

.footerMenu {
	width: 100%;
	background: transparent;
	height: 170px;
	bottom: 100px;
	border-bottom: 2px solid #AAA;
	position: absolute;
	-webkit-box-sizing: border-box;
	-webkit-box-shadow: 0px -17px 24px 0px rgba(50, 50, 50, 0.47);
	-moz-box-shadow: 0px -17px 24px 0px rgba(50, 50, 50, 0.47);
	box-shadow: 0px -17px 24px 0px rgba(50, 50, 50, 0.47);
	vertical-align: middle;
	padding: 40px 40px;
}