.datePickerScreen{
	width: 100vw;
	height: calc(100vh - 275px);
	background-color: #440F11;
	z-index: 9;
	position: relative;
	h2{
		margin: 0;
		color: #FFFFFF;
		font-size: 48px;
		font-weight: bold;
		text-align: center;
		padding-top: 330px;
	}
	.datePickerContainer{
		width: 90%;
		margin: auto;
	}
	.toggleOptionsContainer{
		padding-top: 220px;
		margin: auto;
		width: 740px;
		
		.toggleOption{
			-webkit-box-sizing: border-box;
			width: 370px;
			height: 100px;
			font-size: 36px;
			font-weight: bold;
			color: #FFF;
			background-color: #380C0D;
			text-align: center;
			padding: 25px;
			display: inline-block;
			border-top-left-radius: 10px;
			border-bottom-left-radius: 10px;
			&:nth-child(2){
				border-top-left-radius: 0px;
				border-bottom-left-radius: 0px;	
				border-top-right-radius: 10px;
				border-bottom-right-radius: 10px;
			}
			&.selected{
				background-color: #CE0E2D;
			}
		}
	}
}