
.wrapper {
    background-color: #440F11;
    height: calc(100% - 172px);
    padding: 370px 200px;
    text-align: center;
}

.title {
    font-size: 48px;
    color: white;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 80px;
}

.cheaperOption {
    p {
        font-size: 37px;
        text-align: left;
        color: white;
    }
}

.button {
    width: 100%;
    height: 80px;
	border-radius: 10px;
	color: #382F2D;
	font-size: 32px;
	font-weight: bold;
	border: none;
    background-color: #dadada;
    text-align: left;
    padding: 0 25px;

    &.cheaper {
        background-color: #ce0e2d;
        color: white;
    }
}

.selectedFare {
    margin-top: 100px;
    font-size: 37px;
    .fare {
        margin-bottom: 20px;
        color:white;
        text-align: left;
    }
}