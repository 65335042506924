li.journeyBar {
  display: flex;
  height: 215px;
  padding: 30px 0;
  color:#382F2D;
  font-size: 42px;
  font-weight: bold;
  background-color: #FFF;
  border-top: 1px solid #9A9A9A;
  box-sizing: border-box;
  
  &:nth-child(odd){
    background-color: #ECECEC;
  }
}

.disabled {
  opacity: 0.2;
}

.priceBox {
  width: 200px;
  height: 80px;
  background: #382f2d;
  color: #ffffff;
  text-align: center;
  border-radius: 10px;
  font-size: 42px;
  box-sizing: border-box;
  padding: 2px;
  .penny{
    font-size: 21px;
    padding-top: 22px;
  }
}

.stationName {
  padding-top: 28px;
  padding-bottom: 32px;
  font-size: 48px;
  font-weight: bold;
  color: #382f2d;
  padding-left: 70px;
  width: 424px;
  height: 50px;
  position: relative;
  
  .railcardApplied { 
    position: absolute;
    right: 15px;
  }
}

.stationNameSmall {
  font-size: 36px;
  font-weight: bold;
  color: #382f2d;
  display: flex;
  width: 660px;
  padding-top: 20px;
}
.infoIcon{
  position: relative;
  width: 60px;
  height: 60px;
  margin-left: 40px;
  padding: 10px;
  &:after{
    content: ' ';
    display: block;
    position: absolute;
    background: url('../../assets/information-icon.svg') no-repeat center center;
    border-radius: 25px;
    background-color: #746d6b;
    width: 48px;
    height: 48px;
  }
}
ul.journeyPricesBar{
  list-style: none;
  padding: 0;
  margin: 0;
  li{
    box-sizing: border-box;
    max-height: 130px;
    padding: 30px 45px 30px 100px;
    display: flex;

    .price{
      padding-top: 10px;
    }
    .priceBox {
      height: 75px;
    }

  }
}

.priceContainer {
  display: flex;
  align-items: flex-end;
}

.mainPriceContainer {
  width: 435px;
  justify-content: flex-start;
}

.priceWrapper {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-right: 20px;
  max-width: 200px;
  justify-content: center;
  align-items: center;
  small{
    font-size: 24px;
    margin-bottom: 20px;
  }
}

.moreFaresToggleCol{
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-right: 20px;
  small{
    font-size: 24px;
    margin-bottom: 20px;
  }
  .moreFaresToggle{
    width: 100%;
    height: 35px;
    background: url('../../assets/journey-select-arrow.svg') center center no-repeat;
    &.open{
      transform: rotate(90deg);
    }
  }
}

.price {
  padding-left: 30px;
  padding-top: 15px;
  padding-bottom: 17px;
  display: flex;
}

.duration {
    font-size: 26px;
    color: #382F2D;
    opacity: .5;
    text-decoration: underline;
}

.arrow {
  margin: 0 20px;
}

.railcardApplied {
  width: 50px;
  display: inline-block;
  vertical-align: sub;
  margin-left: 10px;
}

.overtaken {
  position: absolute;
  right: 15px;
}

.overtakenText {
  text-decoration: none;
  font-size: 16px;
  position: absolute;
  padding: 10px 0 0 0;
}
