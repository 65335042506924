@import '../../../styles/variables.scss';

.arrow {
	fill: white;
	margin: 0 15px;
	transform: translateY(-10px);
	margin: 0 10px;
}

.loader {
	position: absolute;
	top: calc(55% - 60px);
	left: calc(50% - 60px);
}

.journeyBarContainer {
	list-style: none;
	padding: 0;
	margin: 0;
	overflow-y: scroll;
	height: 1025px;
	max-height: 800px;
}

.secondaryHeader {
	padding-top: 75px;
	line-height: 50px;
}

.stationName {
	max-width: 455px;
	display: inline-block;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.momentBeingChoosen {
	text-transform: capitalize;
	font-size: 32px;
	font-weight: lighter;
}

.change {
	text-decoration: underline;
	font-weight: bold;
	margin-left: 20px;
}


// Tabs

.tabsRibbon {
	margin-top: -40px;
	background: var(--light-grey) 0% 0% no-repeat padding-box;
	background: #ECECEC 0% 0% no-repeat padding-box;
	width: 100%;
	height: 120px;

	&:after {
		content: ' ';
		width: 100%;
		height: 120px;
		position: absolute;
		background-color: #ECECEC;
		-webkit-box-shadow: 0px -17px 24px 0px rgba(0, 0, 0, 0.65);
		-moz-box-shadow: 0px -17px 24px 0px rgba(0, 0, 0, 0.65);
		box-shadow: 0px -17px 24px 0px rgba(0, 0, 0, 0.65);
	}
}

.tab {
	width: 500px;
	height: 135px;
	margin-left: 28px;
	border-radius: 15px;
	text-align: center;
	background-color: #693F41;
	display: inline-block;
	color: #FFF;
	font-size: 36px;
	font-weight: bold;
	line-height: 120px;
	position: relative;

	&.selected {
		background-color: #ECECEC;
		color: #000;
		position: relative;

		&:after {
			content: ' ';
			position: absolute;
			width: 100%;
			height: 50px;
			background-color: #ECECEC;
			left: 0;
			bottom: 0;
			z-index: 9;
		}
	}

}

.tabList {
	background-color: #ce0e2d;
	height: 135px;
	margin-top: -20px;
	padding: 0px;
}

.tabsContainer {
	margin: 0;
	width: 100%;
	position: relative;
	font-size: 36px;
	font-weight: bold;
	height: 1050px;
}

.restrictionsModal {
	padding: 370px 200px;
	text-align: center;

	.ticketRestrictionTitle {
		font-size: 48px;
		color: white;
		font-weight: bold;
		text-transform: uppercase;
		margin-bottom: 80px;
	}

	.restriction {
		font-size: 37px;
		color: white;
		word-break: break-word;
		font-weight: bold;
	}
}

.button {
	width: 420px;
	height: 80px;
	background-color: #ce0e2d;
	border-radius: 10px;
	color: #ffffff;
	font-size: 32px;
	font-weight: bold;
	border: none;

	&.search {
		display: block;
		margin: 0 auto;
		width: 250px;
		margin-top: 120px;
	}
}

.noFaresFound {
	height: 650px;
	padding: 150px 175px 0;
	background: var(--light-grey) 0% 0% no-repeat padding-box;
	background: #ECECEC 0% 0% no-repeat padding-box;
	transform: translateY(-2px);
	font-weight: bold;
	text-align: center;
	font-size: 48px;
}

.restrictions {
	@include auto-vertical-shadows(800px);
	overflow: scroll;
	margin-bottom: 50px;
}

.passengerOptions {
	position: absolute;
	right: 60px;
	bottom: 145px;
	z-index: 1;
	font-size: 32px;
	font-weight: bold;
	display: flex;
	max-width: 65%;
}

.passengerOptions>button {
	font-weight: bold;
	color: #ce0e2d;
	border: none;
	background-color: Transparent;
}

.passengerText {
	padding-top: 17px;
	padding-left: 30px;
	max-width: 455px;
	text-overflow: ellipsis;
	white-space: pre;
	overflow: hidden;
}

.nextDayLegSolution {
	&:first-of-type {
		display: flex;
		visibility: visible;
	}

	display: none;
	visibility: hidden;
	height: 50px;
	background: #382f2d;
	padding: 5px 65px;
	font-size: 32px;
	color: white;
	align-items: center;

	b {
		margin-right: 10px;
	}
}