@import '../../../styles/variables.scss';

.button {
    background: $round-button-base-background-color;
    border-radius: 80px;    
    outline: none;
    border: none;
    transition: all .3s ease-in-out;
    margin: 0 10px;

    &:disabled {
        background-color: $round-button-base-background-color;
    }

    &.right {
        float: right;
    }
    
    &.left {
        float: left;
    }
}

// Sizes

.normal {
    width: 80px;
    height: 80px;
}

.large {
    width: 100px;
    height: 100px;
}


// Types

.home {
    background-image: url('../../../assets/home-icon.svg');
    background-color: #382F2D;
    background-position: center center;
    background-repeat: no-repeat;
}

.back {
    background-image: url('../../../assets/back-icon.svg');
    background-color: #382F2D;
    background-position: center center;
    background-repeat: no-repeat;
}

.cancel {
    margin-left: 500px;
    background-image: url('../../../assets/cancel.svg');
    color: #FFF;
    text-align: center;
    font-size: 80px;
}

.confirm {
    content: "\2713";
    color: #FFF;
    background-image: url('../../../assets/accept-active.svg');
    background-color: #008a00;
    background-position: center center;
    background-repeat: no-repeat;
}