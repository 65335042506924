.modal {
  position: absolute;
  z-index: 10;
  opacity: 0;
  transition: all 0.3s ease;
  visibility: hidden;
  width: 100vw;
  top: 0;
  height: 100vh;

  &.visible {
    opacity: 1;
    visibility: visible;
  }
}
