.modal {
  position: absolute;
  background: #440f11;
  z-index: 10;
  opacity: 0;
  transition: all 0.3s ease;
  visibility: hidden;
  width: 100vw;
  top: 0;
  height: 100vh;

  padding: 370px 200px;
  box-sizing: border-box;
  text-align: center;

  &.visible {
    opacity: 1;
    visibility: visible;
  }
}

.header {
  font-size: 58px;
  color: white;
  font-weight: bold;
  text-transform: uppercase;
}

.button {
	width: 420px;
	height: 80px;
	background-color: #ce0e2d;
	border-radius: 10px;
	color: #ffffff;
	font-size: 32px;
	font-weight: bold;
	border: none;

	&.search {
		display: block;
		margin: 0 auto;
		width: 250px;
		margin-top: 120px;
	}
}


.travelSegments {
  list-style-type: none;
  font-size: 32px;
  padding: 0;
  margin: 100px 0 ;
  color: white;
  max-height: 1000px;
  overflow: auto;

  li {
    display: flex;
    height: 100px;
    font-weight: bold;
    justify-content: left;
    align-items: center;

    &:before {
      content: '';
      display: inline-block;
      width: 150px;
      height: 100px;
    }

    &.departure, &.arrival {
      span {
        margin: 0 25px 0 0;
      }
    }

    &.departure:before {
      background: url('../../assets/route-start.svg') no-repeat center 25px;
    }
    
    &.arrival:before {
      background: url('../../assets/route-end.svg') no-repeat center -35px;
    }

    &.walkingDirection, &.trainDirection, &.genericDirection {
      border-top: 2px solid white;
      border-bottom: 2px solid white;
    }

    &.trainDirection:before {
      background: url('../../assets/national-rail.png') no-repeat center center;
      background-size: 70px;
    }

    &.walkingDirection:before {
      background: url('../../assets/walking-directions.svg') no-repeat center center;
    }

    &:first-child, &:last-child {
      border-top: none;
    }
  }
}