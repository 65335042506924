.railcardSelector {
  width: 1080px;
  height: 1817px;
  background-color: #440f11;
  //   color:white;
  //   font-size: 26px;
//   display: flex;
//   justify-content: center;
}

.heading {
  color: white;
  text-transform: uppercase;
  font-weight: bold;
  padding-top: 330px;
  padding-left: 306px;
}

.railcard {
  font-size: 36px;
  font-weight: bold;
  color: white;
  padding-top: 13px;
}

.railcardContainer {
    width: 90%;
        margin: auto;
        text-align: center !important;
//   padding-left: 134px;
//   padding-top: 683px;

}

.title {
  color: white;
  font-size: 48px;
  text-transform: uppercase;
  font-weight: bold;
  padding-top: 330px;
  padding-left: 389px;
}